// Custom.scss
// Option A: Include all of Bootstrap

/* import only the necessary Bootstrap files */
@import "./../../node_modules/bootstrap/scss/functions"; 
@import "./../../node_modules/bootstrap/scss/variables";

$theme-colors: (
  "primary": #47718F,
  "secondary":#9CBF75,
    "light": #ECF1DE,
    "dark": #D9DEE6
);

@function theme-color($key: "primary") {
    @return map-get($theme-colors, $key);
}

@import "./../../node_modules/bootstrap/scss/bootstrap";
